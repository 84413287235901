import { Controller } from '@hotwired/stimulus'
import { csrfToken, htmlToElement } from './functions/util'

export default class extends Controller {
  static get targets() {
    return ['errorView']
  }

  connect() {
    // console.debug('[chat][js] connect', this.identifier)
    // this.element['$formItemJsValidation'] = this
  }

  receiver(event) {
    // console.debug('[chat][js] receive change-code', event.detail, event)
    this.validate(event.detail)
  }

  validate({ mode, code }) {
    // console.debug('[chat][code_validation]', mode, code)
    fetch('/user/api/templates/schema_editor/validations/' + mode, {
      method: 'POST',
      mode: 'same-origin', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code: code }),
    }).then(response => {
      if (!response.ok) {
        if (response.status === 401) {
          response.json().then(function(json) {
            console.error('[chat][js]', json.error.text)
            window.alert(json.error.text) // Unauthorized
          })
        } else {
          window.alert('Internal Server Error.')
        }
        throw response;
      }
      return response.text()
    }).then((html) => {
      const jsValidationResultTemplate = htmlToElement(html)
      // console.debug('[chat][js][code-validation]', jsValidationResultTemplate)
      this.errorViewTarget.replaceChildren(jsValidationResultTemplate)
    }).catch(error => {
      console.error('[chat][js] error.', error)
    })
  }
}
