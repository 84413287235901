import { page } from '../../../init'

$(function() {
  if (page.controllerPath() === 'user/profiles/builds/preview') {
    // NOTE: Set chat settings by gon.chat_settings
    page.afterChatLoaded(function() {
      for (const chat_setting_key in gon.chat_settings) {
        console.debug('[chat][settings]', chat_setting_key, gon.chat_settings[chat_setting_key])
        window.chat.settings[chat_setting_key] = gon.chat_settings[chat_setting_key]
      }
    })
  }
})
