
class Page {
  controller() {
    return $('meta[name=smart_dialog]').attr('controller')
  }
  action() {
    return $('meta[name=smart_dialog]').attr('action')
  }
  controllerPath() {
    return $('meta[name=smart_dialog]').attr('controller_path')
  }

  afterChatLoaded(callback, delay = 500, retry = 0) {
    if (retry >= 5) throw '[chat][afterChatLoaded] No chat in global window.'

    setTimeout(() => {
      if ('chat' in window) {
        callback()
      } else {
        console.debug('[chat][afterChatLoaded]', 'retry=', retry + 1)
        setTimeout(() => {
          this.afterChatLoaded(callback, delay, retry + 1)
        }, delay)
      }
    }, 500)
  }

  prettyObject() {
    return {
      controller: this.controller(),
      action: this.action(),
      controllerPath: this.controllerPath(),
    }
  }
}
export const page = new Page()

class Tooltip {
  trigger(options) {
    const defaultOptions = {
      container: 'body',
      boundary: 'window',
      delay: { show: 100, hide: 50 },
    }
    const options_ = {...defaultOptions, ...options}
    return $('[data-toggle="tooltip"]').tooltip(options_)
  }
}
export const tooltip = new Tooltip()

class Popover {
  trigger(options) {
    const defaultOptions = {
      container: 'body',
      boundary: 'window',
      delay: { show: 100, hide: 50 },
      // trigger: 'hover', // click | hover | focus | manual
    }
    const options_ = {...defaultOptions, ...options}
    return $('[data-toggle="popover"]').popover(options_)
  }
}
export const popover = new Popover()

// TODO: remove: deprecated: Use stimulus one
import Sortable from 'sortablejs'
export const createSortable = function(targetElement, options = {}) {
  const defaultOptions = {
    // handle: '#scenarios-accordion .scenario-sortable',
    handle: '.sortable-handle',
    ghostClass: 'bg-secondary',
    animation: 150,
    fallbackOnBody: true,
    swapThreshold: 0.65,
    filter: '.ignore-sortable', // NOTE: selects are not open due to backwards compatibility of `preventOnFilter` condition.
    preventOnFilter: false,     //       https://github.com/SortableJS/Sortable/issues/1537
  }
  const options_ = {...defaultOptions, ...options} // Merging
  Sortable.create(targetElement, options_)
}

class BoostarapSelect {
  constructor() {
    if (gon.bootstrap_select) {
      sessionStorage.setItem('gon.bootstrap_select', JSON.stringify(gon.bootstrap_select))
    } else {
      gon.bootstrap_select = JSON.parse(sessionStorage.getItem('gon.bootstrap_select'))
    }
    this.options = gon.bootstrap_select
  }
  trigger(options) {
    return $('.selectpicker').selectpicker({...this.options, ...options})
  }
}
export const bootstrapSelect = new BoostarapSelect()

import introJs from 'intro.js';
class IntroJS {
  constructor() {
    if (gon.introjs_options) {
      sessionStorage.setItem('gon.introjs_options', JSON.stringify(gon.introjs_options))
    } else {
      gon.introjs_options = JSON.parse(sessionStorage.getItem('gon.introjs_options'))
    }
    this.options = gon.introjs_options
  }
  onClick(target) {
    return $(target).on('click', () => {
      return this.trigger()
    })
  }
  trigger() {
    return introJs().setOptions(this.options).start()
  }
}
export const introJS = new IntroJS()

import * as moment from 'moment' // https://github.com/moment/moment/issues/2608#issuecomment-140537796
const DATE_RANGES = {
    today :        [moment().startOf('day'), moment().endOf('day')],
    yesterday :    [moment().startOf('day').subtract(1, 'day'), moment().endOf('day').subtract(1, 'day')],
    last_7_days :  [moment().subtract(6, 'days'), moment()],
    last_30_days : [moment().subtract(29, 'days'), moment()],
    last_60_days : [moment().subtract(59, 'days'), moment()],
    last_90_days : [moment().subtract(89, 'days'), moment()],
    last_week :    [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
    last_month :   [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    this_month :   [moment().startOf('month'), moment().endOf('month')]
}
class DateRangePicker {
  constructor() {
    if (gon.daterangepicker) {
      sessionStorage.setItem('gon.daterangepicker', JSON.stringify(gon.daterangepicker))
    } else {
      gon.daterangepicker = JSON.parse(sessionStorage.getItem('gon.daterangepicker'))
    }
    this.options = gon.daterangepicker
  }
  triggerSingleDatePicker(targetSelector, { updatedSelector = '', timePicker = false } = {}) {
    const currentYear = parseInt(moment().format('YYYY'), 10)
    // console.debug(currentYear)
    const locale = {
      ...this.options.locale,
      format: timePicker ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD',
    }
    function callback(start) {
      if (updatedSelector) {
        $(updatedSelector).val(
          timePicker ? start.format('YYYY-MM-DD HH:mm:ss ZZ') : start.format('YYYY-MM-DD')
        )
      }
    }
    $(targetSelector).daterangepicker({
      singleDatePicker: true,
      timePicker: timePicker,
      timePicker24Hour: true,
      timePickerSeconds: true,
      showDropdowns: true,
      minYear: 2018,
      maxYear: currentYear + 2,
      locale: locale
    }, function(start) { // _end, _label
      callback(start)
    })
  }
  trigger(targetSelector, defaultRange = '', startSelector = '#start-datetime', endSelector = '#end-datetime') {
    let [start, end] = [moment().startOf('month'), moment().endOf('month')] // this month
    if (defaultRange && Object.keys(DATE_RANGES).includes(defaultRange)) [start, end] = DATE_RANGES[defaultRange]
    if (this.options.daterange_at_gteq && this.options.daterange_at_lteq) {
      start = moment(this.options.daterange_at_gteq, 'YYYY/MM/DD HH:mm:ss')
      end = moment(this.options.daterange_at_lteq, 'YYYY/MM/DD HH:mm:ss')
    }
    function callback(start, end, _label) {
      // console.debug('[chat][JS]', start, end, _label)
      const startWithFormat = start.format('YYYY/MM/DD HH:mm:ss')
      const endWithFormat = end.format('YYYY/MM/DD HH:mm:ss')
      $(targetSelector + ' span').html(startWithFormat + ' - ' + endWithFormat)
      $(startSelector).val(startWithFormat)
      $(endSelector).val(endWithFormat)
    }
    let ranges = {}
    for (const rangeKey in this.options.ranges) {
      ranges[this.options.ranges[rangeKey]] = DATE_RANGES[rangeKey]
    }
    // ranges[this.options.ranges.today] = [moment().startOf('day'), moment().endOf('day')]
    // ranges[this.options.ranges.yesterday] = [moment().startOf('day').subtract(1, 'day'), moment().endOf('day').subtract(1, 'day')]
    // ranges[this.options.ranges.last_7_days] = [moment().subtract(6, 'days'), moment()]
    // ranges[this.options.ranges.last_30_days] = [moment().subtract(29, 'days'), moment()]
    // ranges[this.options.ranges.last_week] = [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')]
    // ranges[this.options.ranges.last_month] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    // ranges[this.options.ranges.this_month] = [moment().startOf('month'), moment().endOf('month')]
    $(targetSelector).daterangepicker({
        timePicker: true,
        timePicker24Hour: true,
        timePickerSeconds: true,
        startDate: start,
        endDate: end,
        ranges: ranges,
        locale: this.options.locale
    }, callback)
    callback(start, end)
  }
}
export const dateRangePicker = new DateRangePicker()
